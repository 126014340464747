.srt {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.btn-default {
    background-color: #00203d;
}

.btn-success {
    background-color: #a2ad00;
}

.btn-error {
    background-color: #c01324;
}

.btn-warning {
    background-color: #feb612;
}

.btn-info {
    background-color: #00a9ed;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background: transparent;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

*
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-size: 16px;
    line-height: 1.5;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

header {
    text-align: center;
    padding: 3em 0;
}

h1 {
    font-weight: 300;
    font-size: 2.625em;
    line-height: 1.0952380952;
    margin-bottom: 0.7619047619em;
    color: #3A3D3F;
}

h2 {
    font-weight: 300;
    font-size: 1.5em;
    margin-bottom: 1.3333333333em;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 110px;
    background: #eaeaea;
}

/* FORM ELEMENTS & CONTROLS */
::-webkit-input-placeholder {
    color: #333 !important;
}

:-moz-placeholder {
    color: #333 !important;
}

::-moz-placeholder {
    color: #333 !important;
}

:-ms-input-placeholder {
    color: #333 !important;
}

label {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    vertical-align: top;
}

.form-control {
    background: #EEEEEE;
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    letter-spacing: 0.2px;
    line-height: 17px;
    padding: 20px;
    margin: 10px 0;
    border: 2px solid #FFFFFF;
    line-height: 1.467;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.form-control:focus {
    border-color: #00a9ed;
    outline: 0;
    box-shadow: none;
    color: #000;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.form-text-row {
    padding: 0 40px;
    margin: 10px 0;
    -border: 1px solid red;
}

.btn-default {
    background: #004165;
    border-radius: 40px;
    font-size: 1.125em;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.0625em;
    line-height: 0.75em;
    padding: 15px 20px;
    text-transform: uppercase;
    width: 100%;
    margin: 12px 0;
}

.btn-default:hover {
    background: #00203d;
    color: #fff;
}

.btn-secondary {
    background: #fff;
    color: #00203d;
}

.btn-default:hover {
    background: #00203d;
    color: #fff;
}

/* Bootstrap Default Media Queries */
@media (max-width: 767px) {
    body {
        /* Margin bottom by footer height */
        margin-bottom: 110px;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 110px;
    background-color: #313233;
    color: #dcddde;
    padding-top: 10px;
    opacity: 0.75;
}

.footer a {
    color: #fff;
}

.footer a:hover {
    text-decoration: none;
}

.footer .footer-info-legal {
    opacity: 0.44;
    padding-top: 10px;
}

.footer .footer-info-legal p {
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 400;
    margin: 0;
}

.footer .footer-info-version p {
    opacity: 0.44;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 400;
}

#header-app-brand-logo {
    display: inline-block;
    height: 90px;
    background: url("./clearfiserv.png") left 50%/contain no-repeat;
    padding-left: 146.6666666667px;
    color: #616365;

}

#header-app-brand-logo span {
    display: table-cell;
    vertical-align: middle;
    border-left: 1px solid #616365;
    padding-left: 10.6666666667px;
    height: 32px;
    font-size: 21.3333333333px;
    font-weight: 300;
    text-align: left;
}

.maxDialog {
    max-width: 1024px;
    width: 100%;
    max-height: 760px;
    height: 95%;
    margin: auto;
}

.maxDialogContent {
    width: 100%;
    height: 100%;
}

.maxDialogHeader {
    max-height: 55px;
}

.maxDialogBody {
    width: 100%;
    height: 92%;
}

.maxIframe {
    width: 100%;
    max-height: 660px;
    height: 100%;
}

.noPadding {
    padding: 0 !important;
}

/* Login Card */
.card {
    background: #fff;
    border-radius: 4px;
    margin: 10px;
    padding: 20px 0;
}

.card h2 {
    border-bottom: 2px solid #eaeaea;
    text-align: center;
}

@media (min-width: 768px) {
    .card {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}

.modal-iframe {
    width: 100%;
    max-height: 660px;
    height: 100%;
}

.modal-content {
    height: 100%;
    max-height: 660px;
}

.modal-dialog {
    height: 100%;
    max-height: 660px;
}

.modal-body {
    height: 100%;
    height: 600px;
}

.alert-area {
    padding-right: 40px;
    padding-left: 40px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.change-password {
    height: 175px;
    -webkit-transition: height 1s;
    transition: height 1s;
    overflow: hidden;
}

.change-password-paragraph {
    height: 25px;
    -webkit-transition: height 1s;
    transition: height 1s;
    overflow: hidden;
}

.no-change-password {
    height: 0px;
    -webkit-transition: height 1s;
    transition: height 1s;
    overflow: hidden;
}

.border-error.ng-invalid-required, .mismatch-password-border-error  {
    border-color: #B20000 !important;
}

small {
    color: #B20000;
}

input:required:invalid {
    outline: none;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333;
  border-radius: 6px;
}
